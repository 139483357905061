import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../../models/DatoCMSModel'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import Hero from '@v/algorithm-updates/hero'
import History from '@v/seo-dictionary/history'

const Page = ({ data, pageContext }) => {
  const { page } = data
  const model = new DatoCMSModel(page)

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={page.title} />
          </div>
        </div>
      </div>

      <Hero
        titleBig={page.heroTitle}
        image={page.heroImage}
        imageAsset={page.heroImageAsset}
      />

      <History
        title={page.categoriesSectionTitle}
        categoryListTitle={page.categoryListTitle}
        updates={data.terms.nodes}
        locale={pageContext.locale}
        dictionaryPageSlug={page.slug}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query AiDictionaryPageQuery($locale: String) {
    page: datoCmsAiDictionaryPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      slug
      heroTitle
      categoriesSectionTitle
      categoryListTitle
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    terms: allDatoCmsAiDictionaryTerm(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      nodes {
        id
        locale
        slug
        title
        smallDescription
        category {
          id
          title
          slug
          orderNumber
        }
      }
    }
  }
`
